export const parseRideEvents = (event: string): string => {
  switch (event) {
    case "ride_requested":
      return "Поездка запрошена";
    case "searching_driver":
      return "Поиск водителя";
    case "driver_found":
      return "Водитель найден";
    case "driver_not_found":
      return "Водитель не найден";
    case "offered_to_driver":
      return "Предложен заказ водителю";
    case "driver_received":
      return "Водитель получил заказ";
    case "driver_accepted":
      return "Водитель принял заказ";
    case "driver_missed":
      return "Водитель пропустил заказ";
    case "driver_declined":
      return "Водитель отклонил заказ";
    case "driver_expecting_rider":
      return "Водитель ждет пассажира";
    case "driver_cancelled":
      return "Водитель отменил заказ";
    case "rider_cancelled":
      return "Пассажир отменил заказ";
    case "driver_pickup":
      return "От борта";
    case "ride_started_for_rider":
      return "Поездка началась";
    case "rider_changed_destination":
      return "Пассажир изменил направление";
    case "pre_ride_wait_started":
      return "Ожидание началось";
    case "pre_ride_wait_ended":
      return "Ожидание закончилось";
    case "wait_started":
      return "Платное ожидание началось";
    case "wait_ended":
      return "Платное ожидание закончилось";
    case "driver_completed":
      return "Поездка завершена";
    case "ride_price_recalculated":
      return "Перерасчет стоимости поездки";
    case "not_served":
      return "Не обслуживается";
    case "not_completed":
      return "Поездка не завершена";
    case "sse_status_success":
      return "Статус поездки успешно отправлен";
    case "sse_status_fail":
      return "Ошибка отправки статуса поездки";
    case "sse_offer_success":
      return "Поездка успешно предложена SSE";
    case "sse_offer_fail":
      return "Ошибка в предложении поездки SSE";
    case "ride_offer_received":
      return "Заказ поступил";
    case "ride_offer_shown":
      return "Заказ показан";
    case "ws_offer_success":
      return "Поездка успешно предложена WS";
    case "ws_offer_fail":
      return "Ошибка в предложении поездки WS";
    case "admin_cancelled":
      return "Отменен администратором";
    case "driver_accepted_as_by_finish":
      return "Водитель взял заказ по завершению";
    case "driver_completed_previous_ride":
      return "Водитель завершил предыдущую поездку";
    case "payment_type_changed":
      return "Был изменен способ оплаты";
    case "admin_set_to_expecting_rider":
      return "Администратор установил статус на месте";
    case "admin_completed":
      return "Администратор завершил поездку";
    case "admin_completed_driver_previous_ride":
      return "Администратор завершил предыдущую поездку водителя";
    case "payment_provider_changed":
      return "Изменение способа оплаты";
    default:
      return event;
  }
};
